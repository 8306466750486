<template>
  <div>
    <section>
      <article class="contact_header">
        <div class="container h-100 d-flex align-items-end text-white">
          <div class="pb-3">
            <h1>Contact Us</h1>
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
              <el-breadcrumb-item>Contact Us</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="contact_body mt-5">
        <div class="container">
          <div>
            <h2 class="text-center">How can we help you?</h2>
            <div class="w-100 d-flex align-items-center justify-content-center">
              <div style="width: 200px;">
                <el-divider><i class="el-icon-sunny"></i></el-divider>
              </div>
            </div>
          </div>

          <section>
            <article>
              <div class="help_grid">
                <div>
                  <el-card
                    shadow="hover"
                    class="h-100 d-flex align-items-center justify-content-center"
                  >
                    <div class="text-center contact-content">
                      <h3 class="m-0 p-0">Address</h3>
                      <span>Uganda, Ruhija, Rubanda</span>
                    </div>
                  </el-card>
                </div>
                <div>
                  <el-card
                    shadow="hover"
                    class="h-100 d-flex align-items-center justify-content-center"
                  >
                    <div class="text-center contact-content">
                      <h3 class="m-0 p-0">Phone Number</h3>
                      <span>+256 704616041</span>
                    </div>
                  </el-card>
                </div>
                <div>
                  <el-card
                    shadow="hover"
                    class="h-100 d-flex align-items-center justify-content-center"
                  >
                    <div class="text-center contact-content">
                      <h3 class="m-0 p-0">Email Address</h3>
                      <span>info@trekkerstaverncottages.com.</span>
                    </div>
                  </el-card>
                </div>
              </div>
            </article>
          </section>

          <section>
            <article>
              <div class="contact_form">
                <div class="row">
                  <div class="col-md-2"></div>
                  <div class="col-md-8">
                    <el-form
                      :model="contactForm"
                      :rules="rules"
                      ref="contactForm"
                      label-width="120px"
                      class="demo-contactForm"
                      label-position="top"
                    >
                      <div class="row">
                        <div class="col-md-6">
                          <el-form-item prop="first_name">
                            <el-input
                              v-model="contactForm.first_name"
                              placeholder="First Name"
                            ></el-input>
                          </el-form-item>
                        </div>
                        <div class="col-md-6">
                          <el-form-item prop="last_name">
                            <el-input
                              v-model="contactForm.last_name"
                              placeholder="Last Name"
                            ></el-input>
                          </el-form-item>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <el-form-item prop="email">
                            <el-input
                              v-model="contactForm.email"
                              placeholder="Email"
                            ></el-input>
                          </el-form-item>
                        </div>
                        <div class="col-md-6">
                          <el-form-item prop="phone">
                            <el-input
                              v-model="contactForm.phone"
                              placeholder="Phone"
                            ></el-input>
                          </el-form-item>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <el-form-item prop="message">
                            <el-input
                              type="textarea"
                              v-model="contactForm.message"
                              placeholder="Message..."
                            ></el-input>
                          </el-form-item>
                        </div>
                      </div>
                      <div class="text-center">
                        <el-form-item class="m-0">
                          <el-button
                            style="background-color: #513F03; color: white; width: 200px;"
                            @click="submitForm('contactForm')"
                          >Submit</el-button>
                        </el-form-item>
                      </div>
                    </el-form>
                  </div>
                  <div class="col-md-2"></div>
                </div>
              </div>
            </article>
          </section>

          <section>
            <article>
              <div class="contact-map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d2042760.9531673922!2d29.978166603748406!3d-0.2892260065449076!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d0.30599499999999996!2d32.5627272!4m5!1s0x19de9fe51b0ec12b%3A0x3cde2c478a61a644!2strekkers%20tavern%20cottages%20uganda!3m2!1d-1.0363159!2d29.776859499999997!5e0!3m2!1sen!2sug!4v1656869917573!5m2!1sen!2sug"
                  width="100%"
                  height="500"
                  style="border:0;"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </article>
          </section>
        </div>
      </article>
    </section>

    <section>
      <article>
        <!-- <div>
          <div class="container">
            <h1>Trekker's Tavern Location</h1>
            <p>
              We are located in the South-west Uganda, in a small village called Ruhija, on the door-steps of
              Bwindi Impenetrable National Park. A tropical Eden, with lush rain-forests, waterfalls, birds, butterflies and above all -
              the rare mountain gorillas.
            </p>
            <p>
              Strategically located on a site carefully selected to give visitors a dramatic view of the
              towering rain forest. On a picture-perfect day, you can get a clear view of all the seven Virunga
              volcanoes. We are 463km from Kampala, Uganda's capital city, 52km from Kabala town and 43km away from
              the park headquarter - Buhoma.
            </p>
          </div>
        </div> -->
      </article>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contactForm: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        message: "",
      },
      rules: {
        first_name: [
          {
            required: true,
            message: "Please input First Name",
            trigger: "blur",
          },
        ],
        last_name: [
          {
            required: true,
            message: "Please input Last Name",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Please input Email",
            trigger: "change",
          },
        ],
        phone: [
          {
            required: true,
            message: "Please input Phone",
            trigger: "change",
          },
        ],
        message: [
          {
            required: true,
            message: "Please input Message",
            trigger: "blur",
          },
        ],
      },
    };
  },

  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  font-family: "Lato", sans-serif;
}

.contact_header {
  height: 40vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)),
    url("../assets/images/home_image1.jpg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}
.contact_header h1 {
  font-size: 1.2em;
}

.contact_body {
  background-color: #f7f7f7;
}

.contact_body .help_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

.contact_body .help_grid > div {
  height: 180px;
}

.contact_body .help_grid .contact-content span {
  font-weight: 300;
  font-size: 0.8em;
}

.contact_form {
  background-color: white;
  margin-top: 20px;
  padding: 50px 50px;
}

.contact-map {
  background-color: white;
  margin-top: 20px;
  margin-bottom: 50px;
}

ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  font-weight: 300;
}

h3 {
  font-size: 1.1em;
  font-weight: 600;
  margin-bottom: 15px;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .contact_body .help_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .contact_body .help_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
}
</style>

<style>
.el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  font-size: 0.8em;
  font-weight: 700;
  text-decoration: none;
  transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: grey;
}

.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  font-size: 0.8em;
  font-weight: 400;
  color: #cfd1d6;
  cursor: text;
}
</style>
